.card {
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-color: #1b1f24;
    border-radius: 2%;
    box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 0.8);
    transition: transform 0.3s;
}

.card:hover {
    transform: translateY(-1em);
}

@media (min-width: 0px) {
    .card { padding-top: 150%; }
}

@media (min-width: 500px) {
    .card { padding-top: 100%; }
}

@media (min-width: 800px) and (orientation: landscape) {
    .card { padding-top: 140%; }
}

@media (min-width: 1000px) and (orientation: landscape) {
    .card { padding-top: 185%; }
}

@media (min-width: 1180px) and (orientation: landscape) {
    .card { padding-top: 150%; }
}

@media (min-width: 1280px) and (orientation: landscape) {
    .card { padding-top: 130%; }
}

@media (min-width: 1360px) and (orientation: landscape) {
    .card { padding-top: 120%; }
}

@media (min-width: 1460px) and (orientation: landscape) {
    .card { padding-top: 110%; }
}

@media (min-width: 1500px) and (orientation: landscape) {
    .card { padding-top: 100%; }
}

.content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 1em;
    overflow: hidden;
    border-radius: inherit;
}

.filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1b1f24;
    opacity: 0.2;
    mix-blend-mode: hard-light;
    z-index: 10;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 20;
}

@media (min-width: 0px) {
    .header {
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
    }
}

@media (min-width: 768px) and (orientation: portrait) {
    .header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1400px) and (orientation: landscape) {
    .header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.title {
    font-size: 1.5em;
}

.body {
    height: 50%;
    position: relative;
}

.img {
    width: 150%;
    border-radius: 2%;
    position: absolute;
    top: 20%;
    transform: rotate(5deg);
}

.testContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
}

.testColor {
    width: 20px;
    height: 20px;
}

.overlay {
    background-color: rgba(27, 31, 36, 0.9);
    display: flex;
    align-items: flex-end;
    opacity: 0;
}

.overlayContainer {
    position: relative;
    bottom: -10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card:hover .content.overlay {
    opacity: 100%;
    transition: opacity 0.5s;
}

.card:hover .overlayContainer {
    transition: 0.5s;
    bottom: 0;
}

.logo {
    width: 30%;
    border-radius: 10%;
    margin-bottom: 1em;
}

.description {
    text-align: left;
}