.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5%;
}

.logo {
    font-size: 1.5rem;
}

@media (min-width: 0px) {
    .logo {
        font-size: 1.3rem;
        margin: 0;
    }
}

@media (min-width: 800px) {
    .logo {
        font-size: 1.5rem;
        margin: 1em 0;
    }
}

.linkContainer {
    display: flex;
    flex-direction: row;
}

.links {
    color: inherit;
    font-size: 1.2em;
    position: relative;
    margin-right: 4em;
}

.links:last-child {
    margin-right: 0;
}

@media (min-width: 0px) {
    .links { margin-right: 0; }
}

@media (min-width: 800px) {
    .links { margin-right: 1em; }
}

@media (min-width: 1300px) {
    .links { margin-right: 3em; }
}

@media (min-width: 1600px) {
    .links { margin-right: 4em; }
}

.wrapperMobile {
    background-color: var(--background-color);
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    padding: 1em 0;
    box-shadow: 0px 10px 30px 0px rgba(4, 51, 78, 1);
    z-index: 100;
}

.wrapperMobile .logo {
    cursor: pointer;
}

@media (min-width: 750px) {
    .wrapperMobile .logo { font-size: 2em; }
}

.menuWrapper {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    box-shadow: 0px 10px 30px 0px rgba(4, 51, 78, 1);
    background-color: var(--background-color);
    z-index: 50;
    transition: 0.5s;
}

.menu {
    margin-top: 3.5em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1.2em;
}

@media (min-width: 650px) and (orientation: landscape) {
    .menu { 
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        margin-top: 5em;
    }

    .menu :last-child {
        flex: 100%;
        background-color: transparent;
        margin-top: 0;
    }

    .menu :not(:last-child, :nth-child(5)) {
        margin-right: 1em;
    }

    .menu a:first-child {
        justify-self: center;
    }
}