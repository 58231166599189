.mobileContainer {
    width: 20%;
    position: relative;
}

@media (min-width: 0px) and (orientation: portrait) {
    .mobileContainer { width: 60% }
}

.mobile {
    width: 100%;
    position: relative;
    z-index: 10;
}

.mobileScreen {
    width: 79%;
    height: 86.5%;
    border-radius: 10px;
    background-color: black;
    position: absolute;
    top: 8.9%;
    left: 11.1%;
    overflow: hidden;
}