.desktopContainer {
    width: 60%;
    position: relative;
}

@media (min-width: 0px) and (orientation: portrait) {
    .desktopContainer { width: 100%; }
}

.desktop {
    width: 100%;
    position: relative;
    z-index: 10;
}

.desktopScreen {
    width: 85.6%;
    height: 88%;
    background-color: black;
    position: absolute;
    top: 4%;
    left: 7.2%;
    overflow: hidden;
}