.wrapper {
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 0px) and (orientation: portrait) {
    .wrapper form {
        width: 90%;
    }
}

@media (min-width: 760px) and (orientation: portrait) {
    .wrapper {
        font-size: 1.5em;
    }

    .wrapper input, .wrapper textarea, .wrapper .label {
        font-size: 1.5em;
    }

    .wrapper button {
        font-size: 1em;
    }
}

.serverErrorContainer {
    width: 30%;
    background-color: rgba(255, 0, 0, 0.5);
    border: 1px solid #f00;
    border-radius: 10px;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    text-align: center;
}

@media (min-width: 0px) and (orientation: portrait) {
    .serverErrorContainer {
        width: 80%;
    }
}

.successMessageContainer {
    width: 30%;
    background-color: rgba(17, 151, 230, 0.5);
    border: 1px solid var(--highlight-color);
    border-radius: 10px;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    text-align: center;
}

@media (min-width: 0px) and (orientation: portrait) {
    .successMessageContainer {
        width: 80%;
    }
}

.header.animation {
    animation: fadeIn 2s backwards;
}

.form {
    display: flex;
    flex-direction: column;
}

.form.animation {
    animation: upSlideFade 1s ease 0.3s backwards;
}

.nameContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.nameContainer > :first-child {
    margin-right: 1em;
}

.button {
    margin: 0;
    color: #fff;
}

/********************************** Animations **********************************/
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes upSlideFade {
    0% { 
        opacity: 0; 
        transform: translateY(10em);
    }
    50% { transform: translateY(0); }
    100% { opacity: 1; }
}

@media (prefers-reduced-motion) {
    *.animation {
        animation: none !important;
    }
}

@media (hover: none) {
    .header.animation {
        animation: fadeIn 2s ease -0.8s backwards;
    }

    .form.animation {
        animation: upSlideFade 2s ease 0.3s backwards;
    }
}