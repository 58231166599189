.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 40%;
    max-height: 70%;
    background-color: hsl(213, 14%, 8%);
    border-radius: 10px;
    border: 2.5px solid #04334e;
    box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 0.8);
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 2em;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.modal::-webkit-scrollbar {
    display: none;
}

@media (min-width: 0px) and (orientation: portrait) {
    .modal { margin-top: -2em; width: 90%; }
}

@media (min-width: 500px) and (orientation: portrait) {
    .modal { width: 70%; }
}

@media (min-width: 0px) and (orientation: landscape) {
    .modal { width: 90%; }
}

@media (min-width: 900px) and (orientation: landscape) {
    .modal { width: 40%; }
}

.headerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #04334e;
    background-color: var(--background-color);
}

.header {
    font-size: 1.2em;
    font-weight: 500;
    width: 90%;
    text-align: center;
}

@media (min-width: 0px) and (orientation: portrait) {
    .header { width: 80%; }
}

.bodyContainer {
    margin: 0 3em;
    margin-top: 2em;
}

@media (min-width: 0px) and (orientation: portrait) {
    .bodyContainer { margin: 0 1em; margin-top: 2em; }
}

@media (min-width: 500px) and (orientation: portrait) {
    .bodyContainer { margin: 0 2em; margin-top: 2em; }
}

.buttonsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media (min-width: 0px) and (orientation: portrait) {
    .buttonsContainer { flex-direction: column; }
}

@media (min-width: 500px) and (orientation: portrait) {
    .buttonsContainer { flex-direction: row; }
}