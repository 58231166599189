.wrapper {
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 750px) and (orientation: portrait) {
    .wrapper { font-size: 1.5em }
}

@media (min-width: 1360px) and (orientation: portrait) {
    .wrapper { font-size: unset; }
}

.header.animation {
    animation: fadeIn 2s backwards;
}

.cardContainer {
    width: 95%;
    margin: 0 2.5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

.cardContainer.animation {
    animation: rightSlideFade 2s ease 0.3s backwards;
}

@media (min-width: 0px) {
    .cardContainer { grid-template-columns: 1fr; }
}

@media (min-width: 800px) and (orientation: landscape) {
    .cardContainer { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1000px) and (orientation: landscape) {
    .cardContainer { grid-template-columns: repeat(3, 1fr); }
}

.miniCardContainer {
    width: 90%;
    margin: 0 5%;
    margin-top: 4em;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;
}

.miniCardContainer.animation {
    animation: fadeIn 1s backwards;
    opacity: 1;
}

@media (min-width: 0px) and (orientation: portrait) {
    .miniCardContainer { 
        width: 80%;
        margin: 0 10%;
        margin-top: 4em;
        grid-template-columns: 1fr; 
    }
}

@media (min-width: 350px) and (orientation: portrait) {
    .miniCardContainer { 
        width: 70%;
        margin: 0 15%;
        margin-top: 4em;
    }
}

@media (min-width: 500px) {
    .miniCardContainer {
        width: 90%;
        margin: 0 5%;
        margin-top: 4em;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 800px) and (orientation: landscape) {
    .miniCardContainer { grid-template-columns: repeat(3, 1fr); }
}

@media (min-width: 1000px) and (orientation: landscape) {
    .miniCardContainer { grid-template-columns: repeat(3, 1fr); }
}

@media (min-width: 1100px) and (orientation: landscape) {
    .miniCardContainer { grid-template-columns: repeat(4, 1fr); }
}

/********************************** Animations **********************************/
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes rightSlideFade {
    0% { 
        opacity: 0;
        transform: translateX(-20em);
    }
    50% { transform: translateX(0); }
    100% { opacity: 1; }
}

@media (prefers-reduced-motion) {
    *.animation {
        opacity: unset !important;
        animation: none !important;
    }
}

@media (hover: none) {
    .header.animation {
        animation: fadeIn 2s ease -0.8s backwards;
    }

    .cardContainer.animation {
        animation: rightSlideFade 2s ease 0s backwards;
    }

    .miniCardContainer.animation {
        animation: fadeIn 1.5s ease -0.7s backwards;
    }
}