.wrapper {
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sadFace {
    margin: 0;
    margin-bottom: 0.2em;
    font-size: 5em;
    color: var(--highlight-color);
    transform: rotate(-90deg);
}

.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5em;
    text-align: center;
}

@media (min-width: 0px) and (orientation: portrait) {
    .textWrapper {
        margin: 0 1em;
    }
}

.backLink {
    cursor: pointer;
}

.or {
    margin: 0;
}