.wrapper {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/images/professional_picture.webp);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position-x: center;
    display: grid;
    grid-template-columns: 50vw 1fr;
}

@media (min-width: 0px) {
    .wrapper {
        height: unset;
        margin-top: 80px;
        background-size: 100%;
        grid-template-columns: 1fr;
        margin-bottom: 4em;
    }
}

@media (min-width: 1000px) {
    .wrapper {
        height: 100vh;
        margin-top: 0;
        background-size: 40%;
        grid-template-columns: 50vw 1fr;
        margin-bottom: 0;
    }
}

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    font-size: 3em;
    font-weight: 500;
    width: 70%;
    position: relative;
    top: -1.5em;
}

.header.animation {
    animation: rightSlideFade 2s backwards;
}

@media (min-width: 0px) {
    .header {
        font-size: 2em;
        width: 80%;
        margin: 0 10%;
        margin-top: 5.5em;
        margin-bottom: 1em;
        text-align: center;
        top: 0;
        background-color: rgba(27, 31, 36, 0.9);
        padding: 0.5em 0;
        border-radius: 1em;
        box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 1);
    }
}

@media (min-width: 0px) and (orientation: landscape) {
    .header {
        margin-top: 13em;
        margin-bottom: 2em;
    }
}

@media (min-width: 360px) and (orientation: portrait) {
    .header {
        margin-top: 8em;
        margin-bottom: 5em;
    }
}

@media (min-width: 400px) and (orientation: portrait) {
    .header { margin-bottom: 6em; }
}

@media (min-width: 540px) and (orientation: portrait) {
    .header { 
        margin-top: 13em;
        margin-bottom: 8em; 
    }
}

@media (min-width: 760px) and (orientation: portrait) {
    .header {
        margin-top: 15em;
        margin-bottom: 2em;
    }
}

@media (min-width: 800px) and (orientation: landscape) {
    .header {
        margin-top: 15em;
        margin-bottom: 2em;
    }
}

@media (min-width: 1000px) {
    .header {
        font-size: 3em;
        width: 70%;
        margin: 0;
        text-align: unset;
        top: -1.5em;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }
}

.header > span {
    color: var(--highlight-color);
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 12%;
    margin-right: 15%;
}

@media (min-width: 0px) {
    .about {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 600px) and (orientation: landscape) {
    .about {
        width: 90%;
        margin: 0 5%;
        background-color: rgba(27, 31, 36, 0.9);
        padding: 0.5em 0;
        border-radius: 1em;
        box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 1);
        font-size: 1.2em;
    }
}

@media (min-width: 760px) {
    .about {
        width: 90%;
        margin: 0 5%;
        background-color: rgba(27, 31, 36, 0.9);
        padding: 0.5em 0;
        border-radius: 1em;
        box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 1);
        font-size: 1.2em;
    }
}

@media (min-width: 1000px) {
    .about {
        width: auto;
        margin: 0;
        margin-top: 12%;
        margin-right: 15%;
        justify-content: flex-start;
        align-items: flex-end;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        font-size: 1em;
    }
}

.aboutContainer {
    width: 60%;
}

@media (min-width: 0px) {
    .aboutContainer { 
        width: 80%; 
        text-align: center; 
    }
}

@media (min-width: 1000px) {
    .aboutContainer { 
        width: 60%; 
        text-align: unset; 
    }
}

.subheader {
    font-weight: 500;
    margin: 0;
    font-size: 1.5em;
}

.subheader.animation {
    animation: leftSlideFade 1s ease 0.3s backwards;
}

.sidetext {
    width: 100%;
    font-size: 1.2em;
    margin-top: 0.5em;
}

.sidetext.animation {
    animation: leftSlideFade 1s ease 0.4s backwards;
}

.stats {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border: 5px solid var(--highlight-color);
    border-right: none;
    border-left: none;
    margin: 2em 0;
}

.stats.animation {
    animation: fadeIn 1s ease 0.8s backwards;
}

.stat {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin: 0 0.5em;
}

@media (min-width: 0px) {
    .stat { font-size: 1em; }
}

@media (min-width: 360px) {
    .stat { font-size: 1.2em; }
}

@media (min-width: 540px) {
    .stat { font-size: 1.5em; }
}

.statNumber {
    font-size: 2em;
    font-weight: 700;
    margin: 0;
    margin-right: 0.2em;
}

@media (min-width: 0px) {
    .statNumber { font-size: 1.5em; }
}

@media (min-width: 1000px) {
    .statNumber { font-size: 2em; }
}

.statNumber > span {
    color: var(--highlight-color);
}

.statDesc {
    width: 5em;
    font-size: 0.8em;
}

.networks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.networks.animation {
    animation: upSlideFade 1s ease 0.5s backwards;
}

.networksIcons {
    margin-top: 1em;
}

.workWrapper {
    width: 100%;
    background-color: hsl(213, 14%, 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 0;
}

.workHeader, .workHeader * {
    margin: 0;
    margin-bottom: 0.5em;
}

@media (min-width: 750px) and (orientation: portrait) {
    .workHeader {
        font-size: 2em;
    }
}

.workLogos {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: center;
    align-items: center;
}

.workLogos.animation {
    animation: fadeIn 1.5s backwards;
}

@media (min-width: 0px) and (orientation: portrait) {
    .workLogos { width: 100%; }
}

@media (min-width: 750px) and (orientation: portrait) {
    .workLogos { width: 70%; }
}

@media (min-width: 600px) and (orientation: landscape) {
    .workLogos { width: 100%; }
}

@media (min-width: 900px) and (orientation: landscape) {
    .workLogos { width: 70%; }
}

.workLogo {
    width: 150px;
    margin: 1em 2em;
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
}

.workLogo:hover {
    filter: none;
    -webkit-filter: grayscale(0);
}

@media (min-width: 0px) and (orientation: portrait) {
    .workLogo {
        width: 80px;
        margin: 1em;
        filter: none;
        -webkit-filter: opacity(100%);
    }
}

@media (min-width: 360px) and (orientation: portrait) {
    .workLogo { width: 100px; margin: 1em 2em; }
}

@media (min-width: 750px) and (orientation: portrait) {
    .workLogo { width: 150px; }
}

@media (min-width: 600px) and (orientation: landscape) {
    .workLogo {
        filter: none;
        -webkit-filter: opacity(100%);
    }
}

@media (min-width: 900px) and (orientation: landscape) {
    .workLogo {
        filter: opacity(50%);
        -webkit-filter: opacity(50%);
    }
}

.featuredWrapper {
    display: grid;
    width: 90%;
    margin: 5em 5%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 3em;
}

@media (min-width: 0px) and (orientation: portrait) {
    .featuredWrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 0px) and (orientation: landscape) {
    .featuredWrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 900px) and (orientation: landscape) {
    .featuredWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

.featuredScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.featuredScreen > div {
    width: 100%;
}

#featured1 { grid-area: 1 / 2 / 3 / 3; }
#featured2 { grid-area: 2 / 1 / 4 / 2; }
#featured3 { grid-area: 3 / 2 / 5 / 3; }

#featured1.animation, #featured3.animation {
    animation: leftSlideFade 1s forwards;
}

#featured2.animation {
    animation: rightSlideFade 1s forwards;
}

#featured1, #featured2, #featured3 { opacity: 0; transition: transform 0.3s; }
#featured1:hover, #featured2:hover, #featured3:hover { transform: translateY(-1em); }

@media (min-width: 0px) and (orientation: landscape) {
    #featured1 { grid-area: 2 / 1 / 3 / 3; }
    #featured2 { grid-area: 2 / 3 / 3 / 5; }
    #featured3 { grid-area: 3 / 2 / 4 / 4; }
}

@media (min-width: 900px) and (orientation: landscape) {
    #featured1 { grid-area: 1 / 2 / 3 / 3; }
    #featured2 { grid-area: 2 / 1 / 4 / 2; }
    #featured3 { grid-area: 3 / 2 / 5 / 3; }
}

.featuredGridWrapper { 
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2em;
    opacity: 0;
}

.featuredGridWrapper.animation {
    animation: rightSlideFade 1s forwards;
}

@media (min-width: 0px) {
    .featuredGridWrapper { margin-left: 0; }
}

@media (min-width: 0px) and (orientation: landscape) {
    .featuredGridWrapper { grid-area: 1 / 1 / 2 / 5; }
}

@media (min-width: 900px) and (orientation: landscape) {
    .featuredGridWrapper {
        grid-area: 1 / 1 / 2 / 2;
        margin-left: 2em; 
    }
}

.featuredHeader {
    font-weight: 500;
    margin: 0;
    font-size: 3em;
}

.featuredHeader * { margin: 0; }

.featuredText {
    font-size: 1.2em;
}

@media (min-width: 0px) and (orientation: portrait) {
    .featuredHeader {
        font-size: 1.5em;
        text-align: center;
    }

    .featuredText { text-align: center; }
}

@media (min-width: 750px) and (orientation: portrait) {
    .featuredHeader { font-size: 3em; }
    .featuredText { font-size: 1.5em; }
}

@media (min-width: 0px) and (orientation: landscape) {
    .featuredHeader, .featuredText { text-align: center; }
}

@media (min-width: 900px) and (orientation: landscape) {
    .featuredHeader, .featuredText { text-align: left; }
}

.projectsTextGridWrapper {
    grid-area: 4 / 1 / 5 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2em;
    opacity: 0;
}

.projectsTextGridWrapper.animation {
    animation: rightSlideFade 1s forwards;
}

@media (min-width: 0px) {
    .projectsTextGridWrapper { margin-left: 0; }
}

@media (min-width: 0px) and (orientation: landscape) {
    .projectsTextGridWrapper { grid-area: 4 / 1 / 5 / 5; }
}

@media (min-width: 900px) and (orientation: landscape) {
    .projectsTextGridWrapper { 
        grid-area: 4 / 1 / 5 / 2;
        margin-left: 2em;
    }
}

/********************************** Animations **********************************/
/* animation: name duration timing-function delay iteration-count direction fill-mode; */
@keyframes rightSlideFade {
    0% { 
        opacity: 0; 
        transform: translateX(-10em);
    }
    50% { transform: translateX(0); }
    100% { opacity: 1; }
}

@keyframes leftSlideFade {
    0% { 
        opacity: 0; 
        transform: translateX(10em);
    }
    50% { transform: translateX(0);}
    100% { opacity: 1; }
}

@keyframes upSlideFade {
    0% { 
        opacity: 0; 
        transform: translateY(10em);
    }
    50% { transform: translateY(0);}
    100% { opacity: 1; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media (prefers-reduced-motion) {
    *.animation {
        animation: none !important;
    }

    #featured1, #featured2, #featured3, .featuredGridWrapper, .projectsTextGridWrapper {
        opacity: 1;
    }
}

@media (hover: none) {
    .header.animation {
        animation: rightSlideFade 2s ease -0.8s backwards;
    }

    .subheader.animation {
        animation: leftSlideFade 2s ease -1s backwards;
    }

    .sidetext.animation {
        animation: leftSlideFade 2s ease -1.1s backwards;
    }

    .stats.animation {
        animation: fadeIn 2s ease -0.5s backwards;
    }

    .networks.animation {
        animation: upSlideFade 2s ease -0.5s backwards;
    }

    .workLogos.animation {
        animation: fadeIn 2s ease -1s backwards;
    }

    #featured1.animation, #featured3.animation {
        animation: leftSlideFade 2s ease -0.5s  backwards;
        opacity: 1;
    }
    
    #featured2.animation {
        animation: rightSlideFade 2s ease -0.5s  backwards;
        opacity: 1;
    }

    .featuredGridWrapper.animation {
        animation: rightSlideFade 2s ease -0.5s backwards;
        opacity: 1;
    }

    .projectsTextGridWrapper.animation {
        animation: rightSlideFade 2s ease -0.5s backwards;
        opacity: 1;
    }
}