.wrapper {
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head {
    display: flex;
    align-items: center;
    justify-content: center;
}

.head.animation {
    animation: fadeIn 2s backwards;
}

.back {
    position: absolute;
    transform: translateY(0.2em);
    left: 2%;
}

.projectName {
    text-align: center;
}

.spotlightContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

#desktopSpotlight {
    animation: rightSlideFade 1s ease 0.3s backwards;
}

#mobileSpotlight {
    animation: leftSlideFade 1s ease 0.3s backwards;
}

@media (min-width: 0px) and (orientation: portrait) {
    .spotlightContainer { 
        flex-direction: column; 
        width: 90%;
    }
}

.content {
    width: 50%;
    margin: 0 25%;
    margin-top: 4em;
    position: relative;
}

@media (min-width: 0px) {
    .content { 
        width: 90%;
        margin: 0 5%;
        margin-top: 4em;
    }
}

@media (min-width: 1300px) {
    .content { 
        width: 50%;
        margin: 0 25%;
        margin-top: 4em;
    }
}

.stackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #12171d;
    box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 0.8);
    border-radius: 1em;
    margin-bottom: 3em;
    padding-bottom: 2em;
    opacity: 0;
}

.stackContainer.animation {
    animation: upSlideFade 1s forwards;
}

.stackItems {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.stackHeader {
    margin: 0;
}

.stackTech {
    width: 3em;
    cursor: pointer;
    transition: transform 0.3s;
    opacity: 0;
}

.stackTech:hover {
    transform: translateY(-0.6em);
}

.stackTech.animation {
    animation: rightSlideFade 2s backwards;
}

.abilitiesContainer {
    width: 50%;
    background-color: #12171d;
    box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 0.8);
    border-radius: 1em;
    float: right;
    margin-left: 2em;
    margin-bottom: 2em;
    padding: 0.5em;
    opacity: 0;
}

.abilitiesContainer.animation {
    animation: leftSlideFade 1s forwards;
}

@media (min-width: 0px) and (orientation: portrait) {
    .abilitiesContainer { 
        float: unset;
        width: unset;
        margin: 0;
        font-size: 0.9em;
    }
}

@media (min-width: 760px) and (orientation: portrait) {
    .abilitiesContainer { 
        float: right;
        width: 50%;
        margin-left: 2em;
        margin-bottom: 2em;
        font-size: unset;
    }
}

.abilitiesHeader {
    text-align: center;
    margin-bottom: 0;
}

.abilities {
    margin-top: 0;
    font-size: 1.2em;
}

.abilitiesLinks {
    display: flex;
    justify-content: space-evenly;
}

.description {
    font-size: 1.2em;
}

.description.animation {
    animation: rightSlideFade 1s backwards;
    opacity: 1;
}

/********************************** Animations **********************************/
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes rightSlideFade {
    0% { 
        opacity: 0; 
        transform: translateX(-10em);
    }
    50% { transform: translateX(0); }
    100% { opacity: 1; }
}

@keyframes leftSlideFade {
    0% { 
        opacity: 0; 
        transform: translateX(10em);
    }
    50% { transform: translateX(0);}
    100% { opacity: 1; }
}

@keyframes upSlideFade {
    0% { 
        opacity: 0; 
        transform: translateY(10em);
    }
    50% { transform: translateY(0);}
    100% { opacity: 1; }
}

@media (prefers-reduced-motion) {
    *.animation {
        opacity: unset !important;
        animation: none !important;
    }

    #desktopSpotlight, #mobileSpotlight {
        animation: none !important;
    }

    .stackContainer, .stackTech, .abilitiesContainer, .description {
        opacity: 1;
    }
}

@media (hover: none) {
    .head.animation {
        animation: fadeIn 2s ease -0.8s backwards;
    }

    #desktopSpotlight {
        animation: rightSlideFade 2s ease -0.5s backwards;
    }
    
    #mobileSpotlight {
        animation: leftSlideFade 2s ease -0.5s backwards;
    }

    .stackContainer.animation {
        animation: upSlideFade 2s ease -1s backwards;
        opacity: 1;
    }

    .abilitiesContainer.animation {
        animation: leftSlideFade 2s ease -1s backwards;
        opacity: 1;
    }

    .description.animation {
        animation: rightSlideFade 2s ease -1s backwards;
    }
}