.wrapper {
    min-height: 100px;
    padding: 1em;
    background-color: #121417;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9em;
}

@media (min-width: 768px) and (orientation: portrait) {
    .footerText {
        font-size: 1.2em;
    }
}

.copyright {
    margin: 0;
    margin-bottom: 1em;
    text-align: center;
}

.languages {
    display: flex;
    justify-content: center;
    align-items: center;
}

.languages p {
    margin: 0;
}

.english {
    margin-right: 1em;
}