:root {
  --highlight-color: #1197e6;
  --background-color: #1b1f24;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
  color: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: rgba(114, 151, 172, 0.5);
  padding: 0 0.5ch;
  border-radius: 2px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}