.miniCard {
    width: 100%;
    border-radius: 5%;
    border: 2.5px solid #04334e;
    box-shadow: 2px 2px 30px 0px rgba(4, 51, 78, 0.8);
    padding: 1em 0;
    cursor: pointer;
    transition: transform 0.3s;
}

.miniCard:hover {
    transform: translateY(-1em);
}

.header {
    font-weight: 500;
    font-size: 1.1em;
    text-align: center;
    margin: 0 1em;
}

.description {
    margin: 0 1em;
    font-size: 0.9em;
    opacity: 0.8;
}

.description > p { margin: 0; }

.tags {
    width: 95%;
    margin: 1em 2.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}